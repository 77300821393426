<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>
        <v-snackbar :timeout="callbackResponse.timeout" color="success" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>
        <v-dialog max-width="500px" v-model="dialogCreate">

            <template v-slot:activator="{ on }">
                <v-btn
                        :success="success"
                        class="mb-2 float-right"
                        color="primary"
                        dark
                        v-on="on"
                >{{$t('add_ticket_category')}}
                </v-btn>
            </template>
            <createDivisionForm :success="success" @formResponse="onResponse"/>
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogEdit">
            <editDivisionForm
                    :serviceID="editedData.serviceID"
                    :success="success"
                    @formResponse="onResponse"
                    v-if="dialogEdit"
            />
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <!--        <v-expansion-panels hover>-->
        <!--            <v-expansion-panel>-->
        <!--                <v-expansion-panel-header>Filter</v-expansion-panel-header>-->
        <!--                <v-expansion-panel-content>-->
        <!--                    <v-container fluid>-->
        <!--                        <v-row align="start">-->
        <!--                            <v-col class="float-right" cols="12" sm="6">-->
        <!--                                <v-select-->
        <!--                                        :items="province"-->
        <!--                                        item-text="value"-->
        <!--                                        item-value="id"-->
        <!--                                        label="Select Province"-->
        <!--                                        v-model="editedData.provinceId"-->
        <!--                                ></v-select>-->
        <!--                            </v-col>-->
        <!--                            <v-col class="float-right" cols="12" sm="6">-->
        <!--                                <v-text-field label="Seartch input" v-model="serverParams.search"></v-text-field>-->
        <!--                            </v-col>-->
        <!--                        </v-row>-->
        <!--                        <v-row align="center" justify="center">-->
        <!--                            <v-btn @click="searchInput" color="primary" dark>-->
        <!--                                <i class="material-icons">search</i>Search-->
        <!--                            </v-btn>-->
        <!--                        </v-row>-->
        <!--                    </v-container>-->
        <!--                </v-expansion-panel-content>-->
        <!--            </v-expansion-panel>-->
        <!--        </v-expansion-panels>-->

        <vue-good-table :columns="columns"
                        :isLoading.sync="isLoading"
                        :pagination-options="{ enabled: true,perPageDropdown: [50, 100,200] }"
                        :rows="rows"
                        :totalRows="totalRecords"
                        @on-column-filter="onColumnFilter"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        mode="remote">
            <template slot="table-column" slot-scope="props">
                <span>
                    {{ $t(props.column.label) }}
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                    <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                    <v-icon @click="deleteItem(props.row)" small>delete</v-icon>
                </span>
            </template>
        </vue-good-table>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createDivisionForm from "./create";
    import editDivisionForm from "./edit";
    import deleteListData from "@/components/deleteModal";
    import {parseJwt} from "@/mixins/parseJwt";

    export default {
        name: "List",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {

                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            createDivisionForm,
            editDivisionForm,
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: 'PilgrimageDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('ticket_category'),
                        disabled: true,
                    },
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columns: [
                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: "ticket_category_name",
                        field: "serviceName"
                    },
                    {
                        label: "category_description",
                        field: "serviceDescription"
                    },
                    {
                        label: "category_rate",
                        field: "serviceRate"
                    },
                    {
                        label: "actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "serviceName",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                }
            };
        },

        mounted() {
            this.loadItems();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.serviceID = props.serviceID;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Pilgrimage/DeletePilgrimageServicesAsync/" + props.serviceID;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    DivisionName: this.serverParams.search,
                    provinceId: parseInt(this.editedData.provinceId)
                };
                axios.post("Pilgrimage/GetPilgrimageServicesListAsync", param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>
